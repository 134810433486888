import * as React from "react";
import { container, styledLink, styleButton } from "./NodeOffer.module.scss";
import Container from "react-bootstrap/Container";
import { Link } from "gatsby";

export const StyledContainer: React.FC<{
  children: JSX.Element | JSX.Element[];
}> = (props) => {
  return (
    <Container className={container} {...props}>
      {props.children}
    </Container>
  );
};

export const StyleButton: React.FC<{
  children: JSX.Element | JSX.Element[];
}> = (props) => {
  return <div className={styleButton}> {props.children}</div>;
};
export const StyledLink: React.FC<{
  to: string;
  children: JSX.Element | string;
}> = (props) => {
  return (
    <Link to={props.to} className={styledLink}>
      {props.children}
    </Link>
  );
};
